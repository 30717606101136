import React from 'react';
import { useCookies } from 'react-cookie';
import loadable from '@loadable/component';

const CookiesPanel = loadable(() => import('./CookiesPanel'));

const CookiesPanelWrapper = ({ policy, location }) => {
  const [ cookies, setCookie ] = useCookies([ 'cookiesConsent' ]);

  return !cookies.cookiesConsent ? (
    <CookiesPanel
      policy={ policy }
      location={ location }
      setCookie={ setCookie }
    />
  ) : null;
};

export default CookiesPanelWrapper;
